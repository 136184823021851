/**************************************
 * Created By LinK On 2020/2/11 22:06.
 **************************************/
import key from './key';
export default [
    {
        point: '@@components',
        namespace: key.ID,
        onload: function () { return [{ key: 'AntAliPayView', component: function () { return import('./ant-ali-pay-view'); } }]; }
    }
];
